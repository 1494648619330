/* eslint-disable react-hooks/exhaustive-deps */
import { Paging, IBaseController } from "ale-base-model";
import { defaultPagination, DEFAULT_PAGE_SIZE } from "ale-base-model/dist/models/Paging";
import { Filter, Search, Sort } from "ale-base-model/dist/models/Query";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";

export function usePagination<T>(props: UsePaginationProps<T>) {
  const [state, setState] = useState<State<T>>({ ...props, loading: false, pagination: { ...props.pagination, ...defaultPagination() } } as State<T>)

  useEffect(() => {
    getList();
  }, [state.pagination.page, state.pagination.pageSize, state.filter, state.search, state.sorts]);

  const onChangePage = (page: number, pageSize?: number) => {
    setState({ ...state, pagination: { ...state.pagination, page, pageSize: pageSize || state.pagination?.pageSize || DEFAULT_PAGE_SIZE } });
  };

  const getList = () => {
    const { pagination: { page, pageSize }, filter, search, sorts } = state
    setState({ ...state, loading: true });

    return props.controller
      .list({ page: page, pageSize, filter, search, sorts })
      .then((pagination: Paging<T>) => {
        if (pagination.rows?.length === 0 && pagination.page > 1)
          setState({ ...state, pagination: { ...state.pagination, page: pagination.page - 1 } });
        else setState({ ...state, pagination });
        return pagination;
      });
  };

  const onChangeFilter = (filter: Filter<T>) => {
    setState({ ...state, filter });
  };

  const onChangeSearch = (search: Partial<Search<T>>) => {
    console.log({state: state.search, search});
    onChangeSearchDebounce({ ...state.search, ...search } as Search<T>);
  };

  const onChangeSorts = (sorts: Sort<T>[]) => {
    setState({ ...state, sorts });
  };

  const onChangeSort = (sort: Sort<T>) => {
    const sorts = (state.sorts || []).slice();
    const index = sorts.findIndex(s => s.field == sort.field);
    if (index >= 0) setState({ ...state, sorts: state.sorts?.map(s => s.field == sort.field ? sort : s) })
    else setState({ ...state, sorts: sorts.concat([sort]) })
  };

  const onChangeSearchDebounce = useCallback(
    _.debounce((search: Search<T>) => {
      setState({ ...state, search });
    }, 500),
    []
  );

  return {
    ...state,
    onChangePage,
    onChangeFilter,
    onChangeSearch,
    onChangeSorts,
    onChangeSort,
    getList,
  };
}

interface State<T> {
  loading: boolean
  pagination: Paging<T>
  search: Search<T>
  filter?: Filter<T>
  sorts?: Sort<T>[]
}

export interface UsePaginationProps<T> {
  controller: IBaseController<T>;
  pagination: Partial<Paging<T>>
  search?: Partial<Search<T>>
  filter?: Filter<T>
  sorts?: Sort<T>[]
}
